import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "../components/AniLink"

import topRemitLogo from "../assets/images/portfolio/top-remit/portfolio-top-remit.png"
import kubidoBrand from "../assets/images/portfolio/kubido/kubido-phone-1.png"
import f2cfurnitureBrand from "../assets/images/portfolio/f2c-furniture/portfolio-f2c-furniture.png"
import sirsalonBrand from "../assets/images/portfolio/sir-salon/sir-salon.png"

function PortfolioPage() {
  return (
    <Layout>
      <SEO
        title="Portfolio"
        description="We create innovative software solutions to tackle tech challenges across multiple industries."
      />
      <section id="portfolio">
        <span className="portfolio-wave-1" />
        <div className="container section-200">
          <div className="text-center">
            <div className="subtitle color-cerulean-blue mb-0">PORTFOLIO</div>
            <div className="title">Featured Use Case</div>
          </div>

          <div className="d-flex flex-column section-top-100">
            <div className="card portfolio-showcase-card mb-5">
              <div className="row">
                <div className="item-direction">
                  <img src={topRemitLogo} className="card-image" alt="portfolio-top-remit" />
                  <div className="right-content">
                    <div className="py-5 px-0 px-sm-2 px-md-5">
                      <div className="portfolio-sub-header color-cerulean-blue mb-0">Finance</div>
                      <div className="portfolio-header">Top Remit</div>
                      <div>
                        <AniLink to="/portfolio/top-remit">
                          <div className="button button-primary button-md-regular d-inline-block">Learn more</div>
                        </AniLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card portfolio-showcase-card mb-5">
              <div className="row">
                <div className="item-direction">
                  <img src={f2cfurnitureBrand} className="card-image" alt="portfolio-f2c" />
                  <div className="right-content">
                    <div className="py-5 px-0 px-sm-2 px-md-5">
                      <div className="portfolio-sub-header color-cerulean-blue mb-0">Furniture</div>
                      <div className="portfolio-header">F2C Furniture</div>
                      <div>
                        <AniLink to="/portfolio/f2c-furniture">
                          <div className="button button-primary button-md-regular d-inline-block">Learn more</div>
                        </AniLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card portfolio-showcase-card">
              <div className="row">
                <div className="item-direction">
                  <img src={sirsalonBrand} className="card-image" alt="portfolio-sirsalon" />
                  <div className="right-content">
                    <div className="py-5 px-0 px-sm-2 px-md-5">
                      <div className="portfolio-sub-header color-cerulean-blue mb-0">Salon</div>
                      <div className="portfolio-header">Sir Salon</div>
                      <div>
                        <AniLink to="/portfolio/sir-salon">
                          <div className="button button-primary button-md-regular d-inline-block">Learn more</div>
                        </AniLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">Partner with us!</div>
            <div className="mb-5 color-spring-green">
              Start scaling up your business and be one of our successful partner!
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default PortfolioPage
